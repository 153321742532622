import "./App.css";
import Moon from "./components/ImageMoon.js";
import Button from "./components/button.js";

function App() {
  return (
    <div className="App">
      <section className="header">
        <div className="container">
          <Moon />
          <div className="header__text">
            <h1>$SUNMOON</h1>
          </div>
          <div className="header__text_left">
            <h1>CA:</h1>
          </div>
        </div>
      </section>
      <section className="about">
        <div className="container">
          <div className="about__button-grid">
            <Button href="#!">SUNPUMP</Button>
            <Button className="button-tg" href="#!">
              TG
            </Button>
          </div>
          <div className="about__text">
            <h1>WELCOME A NEW STAR IN OUR GALAXY, $SUNMOON!</h1>
          </div>
          <Moon />
          <div className="about__text_two">
            <h1>IT’S A FREAKY AHH COMBINATION OF SUN AND MOON!</h1>
            <br />
            <h1>
              JOIN IT IN A JOURNEY OF BECOMING THE RICHEST FREAK PLANET ON
              SUNPUMP.MEME
            </h1>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
