import React from "react";
import "./button.css";

const Button = ({ href, children, className }) => {
  return (
    <a
      href={href}
      className={`button-link ${className}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default Button;
