import "./ImageMoon.css";
import moon from "../images/moon.png";

function Moon() {
  return (
    <div className="image-width">
      <img src={moon} alt="moon" />
    </div>
  );
}

export default Moon;
